import React from "react"
import Layout from "../components/layouts/Layout.js"
import { graphql, useStaticQuery } from "gatsby"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../components/GatsbyLink"
import Banner from "../components/Banner.js"
import * as styles from "../stylesheets/pages/our-bylaws.module.scss"

const OurBylaws = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicOurBylaws {
        data {
          banner_header {
            text
          }
          banner_image {
            alt
            url
          }
          banner_sub_header {
            text
          }
          buttons {
            button_text
          }
          header {
            text
          }
          text {
            richText
          }
          title {
            text
          }
        }
      }
    }
  `).prismicOurBylaws.data

  // const buttons = data.buttons.map((button) => {
  //   return <button className={styles.button}>{button.button_text}</button>
  // })

  console.log(data)

  return (
    <Layout>
      <Banner
        subHeader={data.banner_sub_header.text}
        alt={data.banner_image.alt}
      />
      <div className={styles.container}>
        <h2>{data.header.text}</h2>
        <div className={styles.description}>
          <RichText
            render={data.text.richText}
            serializeHyperlink={GatsbyLink}
          />
        </div>
        <div className={styles.buttonContainer}>
          <a href="https://drive.google.com/file/d/1hZv2oMBKX92nmVPW1AmYH4L9UW05pgdX/view?usp=sharing">
            <div className={styles.button}>Boston Bylaws</div>
          </a>
          <a href="https://drive.google.com/file/d/1WYXFEPsRe7qpKJ9jgsKib0Xqb_ANRrm4/view?usp=sharing">
            <div className={styles.button}>San Francisco Bylaws</div>
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default OurBylaws
